import {Component, Input} from '@angular/core'
import {BankIdService, CollectResponse, StartLoginResponse} from '../../services/bank-id.service'
import {WaitDialogComponent, WaitDialogData} from '../wait-dialog/wait-dialog.component'
import {SignDialogComponent} from '../sign-dialog/sign-dialog.component'
import {MatDialog, MatDialogRef} from '@angular/material/dialog'
import {ConfigService} from '../../services/config.service'
import {HttpErrorResponse} from '@angular/common/http'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {SignDialogData} from '../sign-dialog/data-types'
import {filter} from 'rxjs/operators'
import {KycService} from '../../services/kyc.service'
import {AdminService} from '../../services/admin.service'

/**
 * Calls BankID as necessary and when done (or failed)
 * it emits an event that (someone) can listen to.
 */
@Component({
  selector: 'spb-bankid',
  templateUrl: './bankid.component.html',
  styleUrls: ['./bankid.component.scss']
})
export class BankIdComponent {
  /**
   * Custom signing data, most of the time this is for identification
   * and login is used as default. We pass it untouched onto the collect
   * (SignDialogComponent).
   */
  @Input() public data: SignDialogData = {
    type: 'login',
    title: 'Legitimera',
    startText: 'Starta BankID-programmet på den enhet du har det installerat',
    actionText: 'Skriv in din säkerhetskod i BankID-appen och välj Legitimera.',
    doneText: 'Tack'
  }
  /**
   * This is so that you can set the button label as you wish.
   */
  @Input() public label = 'Identifiera'
  /**
   * Prefilled assumes valid personnummer and hides the input field and make the button light
   * up.
   */
  public prefilled = false
  /**
   * A general error message
   */
  public errorMessage = ''
  /**
   * Details of the error.
   */
  public errorDetail = ''
  /**
   * Keep a reference "globally" to the sign dialog
   */
  public signDialogRef: MatDialogRef<SignDialogComponent> | undefined
  /**
   * The login form.
   */
  public loginForm = new FormGroup({
    personNummer: new FormControl('', [Validators.required,
      Validators.minLength(10), Validators.maxLength(13)
    ])
  })

  /**
   * Used to control the personNummer field if it should
   * be used as passwords or not.
   */
  public passwordMode = false
  public hidePassword = false

  /**
   * Set to true to use password input
   */
  @Input() set password(v: boolean) {
    this.passwordMode = v
    this.hidePassword = v
  }

  private waitData: WaitDialogData = {
    title: 'Vänta...',
    text: 'Vi förbereder BankID'
  }

  /**
   * The personNummer to use when logging in.
   */
  @Input()
  set personNummer(personNummer: string) {
    this.loginForm.controls.personNummer.setValue(personNummer)
    this.loginForm.controls.personNummer.disable()
    this.prefilled = true
  }

  /**
   * Default constructor
   */
  constructor(
    private configService: ConfigService,
    private bankIdService: BankIdService,
    private kycService: KycService,
    private adminService: AdminService,
    private signDialog: MatDialog,
    private waitDialog: MatDialog,
  ) {
  }

  /**
   * Starts the login flow unless we are already logged.
   */
  login(): void {
    this.errorMessage = ''
    const waitRef = this.waitDialog.open(WaitDialogComponent, {
      disableClose: true,
      data: this.waitData
    })
    let bankIdFunction = this.bankIdService.startLogin(this.loginForm.controls.personNummer.value)
    if (this.data.type === 'sign') {
      bankIdFunction = this.kycService.startGuardianSign(this.data.id)
    }
    if (this.data.type === 'supervisorSign') {
      bankIdFunction = this.adminService.startSupervisorSign(this.data.id, this.loginForm.controls.personNummer.value, this.data.adminData)
    }
    bankIdFunction
      .subscribe({
        next: (startResponse: StartLoginResponse) => {
          waitRef.close()
          if (!startResponse.orderRef) {
            this.errorMessage = 'Vi kunde inte anropa BankID. Kontrollera ditt personnummer och försök igen'
            return
          }
          this.data.orderRef = startResponse.orderRef
          this.signDialogRef = this.signDialog.open(SignDialogComponent, {
            disableClose: true,
            data: this.data
          })

          this.signDialogRef.afterClosed()
            .pipe(
              filter(res => res.status === 'ok'))
            .subscribe({
              next: ((res: CollectResponse) => {
                res.orderRef = this.data.orderRef
                res.type = this.data.type
                this.configService.setToken(res)
                this.bankIdService.bankIdResult.next(res)
              })
            })
        },
        error: (err: HttpErrorResponse) => {
          waitRef.close()
          this.errorMessage = 'Kunde inte starta BankID'
          this.errorDetail = err.error.errorMessage
        }
      })
  }
}
