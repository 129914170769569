<title matDialogTitle>{{data.title}}</title>
{{statusText}}
<mat-dialog-actions>
  <button (click)="cancel()"
          [matDialogClose]="{status: 'cancel'}"
          color="primary"
          mat-raised-button
  >Avbryt
  </button>
</mat-dialog-actions>
