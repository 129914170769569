export const environment = {
  production: false,
  kycServiceUrl: 'https://kyc-daniel.kundkannedom.sparbankensyd.se/api',
  commonServiceUrl: 'https://kyc-daniel.kundkannedom.sparbankensyd.se/service',
  authServiceUrl: 'https://spb-common-api-daniel.internal.sparbankensyd.se/service',
  debug: true,
  showStartBankid: false,
  domain: 'kyc-daniel.internal.sparbankensyd.se',
  mock: true,
  testData: {
    guardians: [{
      name: 'Daniel Bergdahl',
      personNummer: '7309193592',
      email: 'rexsuecia@gmail.com'
    }, {
      name: 'Giovanna Tajani',
      personNummer: '7401019521',
      email: 'giovanna.tajani@gmail.com'
    }
    ]
  }
}
