import {Component, Inject, OnDestroy, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {Subscription, timer} from 'rxjs'
import {mergeMap, take} from 'rxjs/operators'
import {BankIdService, CollectResponse} from '../../services/bank-id.service'
import {SignDialogData} from './data-types'

@Component({
  selector: 'spb-sign-dialog',
  templateUrl: './sign-dialog.component.html'
})
export class SignDialogComponent implements OnInit, OnDestroy {

  /**
   * Temp for test
   */
  public statusText = 'Starta BankID på din mobila enhet'

  /**
   */
  public errorMessage = ''

  /**
   * The subscription to check for BankID completion.
   */
  private countDown: Subscription = new Subscription()

  constructor(public dialogRef: MatDialogRef<SignDialogComponent>,
              private bankIdService: BankIdService,
              @Inject(MAT_DIALOG_DATA) public data: SignDialogData
  ) {
  }

  ngOnInit(): void {
    /**
     * When we start we start to look for completion of BankID sign.
     */
    this.countDown = timer(0, 2000)
      .pipe(
        take(60), // = 120 seconds, just over two minutes. Should be sufficient??
        mergeMap(() => this.bankIdService.collect(this.data.orderRef))
      )
      .subscribe({
        next: (response: CollectResponse) => {
          if (response.hintCode === 'outstandingTransaction') {
            this.statusText = this.data.startText
          }
          if (response.hintCode === 'userSign') {
            this.statusText = this.data.actionText
          }
          if (response.hintCode === 'userCancel') {
            this.statusText = 'Avbrutet i BankID applikationen'
            this.cancel()
            this.dialogRef.close({status: 'cancel'})
            this.countDown.unsubscribe()
          }

          if (response.accessToken) {
            this.countDown.unsubscribe()
            this.dialogRef.close({status: 'ok', accessToken: response.accessToken})
          }
        },
        error: error => {
          this.countDown.unsubscribe()
          this.dialogRef.close({status: 'error', code: error.status})
        }
      })
  }

  cancel(): void {
    this.bankIdService.cancel(this.data.orderRef).subscribe()
  }

  ngOnDestroy() {
    // Unsubscribe to avoid leakage.
    this.countDown.unsubscribe()
  }
}
