import {Directive, ElementRef} from '@angular/core'
import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms'
import {Observable, of, timer} from 'rxjs'
import {catchError, map, switchMap} from 'rxjs/operators'
import {BankIdService, PersonnummerResponse} from '../../../services/bank-id.service'

@Directive({
  selector: '[spbPersonnummerValidator]',
  providers: [
    {provide: NG_ASYNC_VALIDATORS, useExisting: PersonnummerValidator, multi: true}
  ]
})
export class PersonnummerValidator implements AsyncValidator {
  constructor(
    private bankIdService: BankIdService,
    private elementRef: ElementRef<HTMLInputElement>
  ) {
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    // Needed to avoid checks when the validator is called outside
    // normal navigation
    if (control.value === '' || control.value === null) {
      return of(null)
    }
    return timer(1000)
      .pipe(
        switchMap(() => this.bankIdService.validatePersonNummer(control.value)),
        catchError(() => of({})),
        map((response: PersonnummerResponse) => {
          if (response['13']) {
            this.elementRef.nativeElement.value = response['13']
            return null
          }
          return {error: 'Ogiltigt personnummer'}
        })
      )
  }
}

