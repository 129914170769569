<div class="mat-typography">
  <div *ngIf="errorMessage" class="spb-error">
    <div>{{errorMessage}}</div>
    <div class="error-detail">({{errorDetail}})</div>
  </div>
  <form [formGroup]="loginForm">
    <div class="input">
      <mat-form-field floatLabel="always" hideRequiredMarker>
        <mat-label>Personnummer</mat-label>
        <input
          [type]="hidePassword ? 'password' : 'tel'"
          formControlName="personNummer"
          matInput
          name="personNummer"
          placeholder="ÅÅÅÅMMDD-NNNN"
          spbPersonnummerValidator/>
        <button (click)="hidePassword = !hidePassword" *ngIf="passwordMode" mat-button mat-icon-button matSuffix>
          <mat-icon class="visibility"><span *ngIf="hidePassword">visibility</span><span
            *ngIf="!hidePassword">visibility_off</span></mat-icon>
        </button>

      </mat-form-field>
      <button
        (click)="login()"
        [disabled]="!loginForm.valid && !prefilled"
        color="primary"
        mat-raised-button>
        {{label}}
      </button>
      <div *ngIf="loginForm.statusChanges | async as status">
        <div *ngIf="status === 'PENDING'" class="pending">Kontrollerar personnummer...</div>
      </div>
    </div>
    <mat-error *ngIf="loginForm.get('personNummer')?.errors?.error">
      {{loginForm.get('personNummer')?.errors?.error}}
    </mat-error>
  </form>
</div>
