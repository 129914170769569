import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'

export interface WaitDialogData {
  title: string
  text: string
}

@Component({
  selector: 'spb-wait-dialog',
  templateUrl: './wait-dialog.component.html',
  styleUrls: ['./wait-dialog.component.scss']
})
export class WaitDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: WaitDialogData) {
  }
}
