import {Injectable} from '@angular/core'
import {BankIDResult} from './bank-id.service'
import {HttpHeaders} from '@angular/common/http'
import {filter} from 'rxjs/operators'
import {Subject} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  public ready = false

  protected httpOptions = {
    headers: new HttpHeaders({
      authorization: 'no-good'
    })
  }

  constructor(private bankIdResult: Subject<BankIDResult>) {
    bankIdResult.pipe(
      filter((result: BankIDResult) => !!result && !!result.accessToken)
    ).subscribe({
      next: ((result: BankIDResult) => {
        this.setToken(result)
      })
    })
  }

  public setToken(result: BankIDResult): void {
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', `Bearer ${result.accessToken}`)
    this.ready = true
  }
}
