import {ErrorHandler, Injectable} from '@angular/core'

/**
 * https://medium.com/@amcdnl/global-error-handling-with-angular2-6b992bdfb59c
 *
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    throw error
  }
}
