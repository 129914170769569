import {BrowserModule} from '@angular/platform-browser'
import {ErrorHandler, NgModule} from '@angular/core'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {CustomMaterialsModule} from './custom-materials-module'
import {HttpClientModule} from '@angular/common/http'
import {SpbCommonModule} from './common/common.module'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'

import {registerLocaleData} from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import localeFrExtra from '@angular/common/locales/extra/fr'
import {GlobalErrorHandler} from './application/error-hanlder'
import {MatDialogModule} from '@angular/material/dialog'
import {CheckboxValidator} from './directives/checkbox/CheckboxValidator'
import {CareOfValidator} from './directives/careOf/CareOfValidator'

registerLocaleData(localeFr, 'fr', localeFrExtra)


@NgModule({
  declarations: [
    AppComponent,
    CheckboxValidator,
    CareOfValidator
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CustomMaterialsModule,
    HttpClientModule,
    SpbCommonModule,
    MatDialogModule
  ],
  providers: [
    WINDOW_PROVIDERS,
    LOCAL_STORAGE_PROVIDERS,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
