import {Inject, Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {LOCAL_STORAGE} from '../application/localstorage.provider'
import {GenericTypesService} from '../application/data-types'
import version from '../../assets/package.json'
import {BankIdService, PersonnummerResponse, StartLoginResponse} from './bank-id.service'
import {BaseService} from './base.service'
import {Finalize} from './finalize.interface'
import {SignDialogData} from '../common/sign-dialog/data-types'

@Injectable({
  providedIn: 'root'
})
export class KycService extends BaseService implements Finalize{

  public admin = false

  public signDialogData: SignDialogData = {
    type: 'sign',
    title: 'Signera',
    startText: 'Starta BankID-programmet på den enhet du har det installerat',
    actionText: 'Skriv in din säkerhetskod i BankID-appen och välj Signera.',
    doneText: 'Tack'
  }

  public completeData = {
    title: 'Tack för din hjälp!',
    text: 'Stort tack för att du håller informationen uppdaterad. Du kan nu stänga detta webbläsarfönster.',
    close: false,
  }

  constructor(
    private httpClient: HttpClient,
    private bankIdService: BankIdService,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
    super(bankIdService.bankIdResult)
  }

  public getRandomPersonnummer(): Observable<PersonnummerResponse> {
    const url = `${environment.authServiceUrl}/personnummer`
    return this.httpClient.get<PersonnummerResponse>(url)
  }

  public startGuardianSign(id: string): Observable<StartLoginResponse> {
    const url = `${environment.kycServiceUrl}/guardian/sign`
    return this.httpClient.put<StartLoginResponse>(url, {id}, this.httpOptions)
  }

  /**
   * Once signature is received we finalize the KYC.
   */
  public finalize(orderRef: string): Observable<any> {
    const url = `${environment.kycServiceUrl}/finalize/${orderRef}`
    return this.httpClient.get<any>(url, this.httpOptions)
  }

  public finalizeGuardian(id: string, orderRef: string, guardianRef: string): Observable<any> {
    const url = `${environment.kycServiceUrl}/guardian/finalize`
    const data = {id, orderRef, guardianRef}
    return this.httpClient.put<any>(url, data, this.httpOptions)
  }

  public getKycForGuardian(): Observable<any> {
    const url = `${environment.kycServiceUrl}/guardian`
    return this.httpClient.put<any>(url, {}, this.httpOptions)
  }

  public submit(report: any): Observable<any> {
    const url = `${environment.kycServiceUrl}/submit`
    return this.httpClient.put<any>(url, report)
  }

  public submitMinor(payload: any): Observable<any> {
    const url = `${environment.kycServiceUrl}/minor/submit`
    return this.httpClient.put<any>(url, payload)
  }

  /**
   * Local storage helper functions. Putting this in a service makes
   * testing of the application much easier.
   */
  public setResultInStorage(result: any): void {
    this.injectedLocalStorage.setItem('kyc-data', JSON.stringify(result))
  }

  public getStoredVersion(): any {
    const storedForm = this.injectedLocalStorage.getItem('kyc-data')
    if (storedForm) {
      const storedData = JSON.parse(storedForm)
      if (storedData.version === version.version) {
        return storedData
      }
    }
    return GenericTypesService.getResultTemplate()
  }
}
